import React from 'react'
import { handleAuthentication } from 'utils'
import { CallbackLoader } from 'components'

const Callback = () => {
  // handleAuthentication()
  return <CallbackLoader />
}

export default Callback
